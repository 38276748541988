import * as React from "react";
import { Component } from "react";
import IntervalPicker from "Components/Input/IntervalPicker";
import Button from "../Components/Buttons/Button";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, FormControlLabel, Link, TextField } from "@mui/material";
import { Acquisitionparameterspost, Sensor, Wirelesssettingspreset, Iotdeploymentpost, Iotdeployment, Wavemode, Devicedeployments, Portparameterspost, Wirelesssettings } from "@inductosense/typescript-fetch";
import DeleteIconButton from "../Components/Buttons/Composite/DeleteIconButton";
import SensorSelectModalPanel from "../Panels/Composite/Modals/SensorSelectModalPanel";
import SensorGroup from "../Model/SensorGroup";
import Services from "Services/Platform/Services";
import DropDownListControlled from "../Components/Input/DropDownListControlled";
import FirstIfNotEmpty from "../Utilities/FirstIfNotEmpty";
import _ from "lodash";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import WarningIcon from "../Components/Graphics/Icons/WarningIcon";
import DropDownButton from "../Components/Buttons/DropDownButton";

dayjs.extend(utc);

interface CreateDeploymentProps {
    rootSensorGroup: SensorGroup;
    initialDeployment: Iotdeployment | null;
    deviceId: string;
    onCreateClick(
        iotDeployment: Iotdeploymentpost
    ): void;
    deviceDeployments: Devicedeployments[];
}

interface CreateDeploymentState {
    intervalSeconds: number;
    wirelessSettingPresets: Wirelesssettingspreset[] | undefined;
    selectedWirelessSettingPreset: Wirelesssettingspreset | undefined;
    acquisitions: Acquisitionparameterspost[];
    //rdcAcquisitionChirpParameters: Rdcacquisitionchirpparameters[];
    portParameters: Portparameterspost[];
    sensorSelectModalPanelShown: boolean;
    selectingSensorForPort: number | null;
    showAdvanced: boolean;
    location: string;
}

export default class CreateDeployment extends Component<CreateDeploymentProps, CreateDeploymentState> {
    constructor(props: CreateDeploymentProps) {
        super(props);
        const { initialDeployment } = this.props;

        this.state = {
            ...this.getStateFromDeployment(initialDeployment, undefined),
            sensorSelectModalPanelShown: false,
            selectingSensorForPort: null,
            wirelessSettingPresets: undefined,
            selectedWirelessSettingPreset: undefined,
            location: initialDeployment?.locationTag || "",
            showAdvanced: false
        };
    }

    private findMatchingPreset(wirelessSettings: Wirelesssettings | undefined, wirelessSettingsPresets: Wirelesssettingspreset[]) {
        if (wirelessSettings !== undefined) {
            for (const preset of wirelessSettingsPresets) {

                const isEqual = _.isEqual(
                    [
                        preset.advertisingChannel,
                        preset.advertisingIntervalInMilliseconds,
                        preset.connectionIntervalInMilliseconds,
                        preset.connectionTimeoutInMilliseconds,
                        preset.modulationMode,
                        preset.payloadSizeInBytes,
                        preset.securityLevel,
                        preset.transmitPowerInDecibelMilliwatts
                    ],
                    [
                        wirelessSettings.advertisingChannel,
                        wirelessSettings.advertisingIntervalInMilliseconds,
                        wirelessSettings.connectionIntervalInMilliseconds,
                        wirelessSettings.connectionTimeoutInMilliseconds,
                        wirelessSettings.modulationMode,
                        wirelessSettings.payloadSizeInBytes,
                        wirelessSettings.securityLevel,
                        wirelessSettings.transmitPowerInDecibelMilliwatts
                    ]
                );

                if (isEqual) {
                    return preset;
                }
            }
        }

        return wirelessSettingsPresets.find(w => w.name.toLowerCase().includes("long"));
    }

    private getStateFromDeployment(deployment: Iotdeployment | null, wirelessSettingPresets: Wirelesssettingspreset[] | undefined) {
        const intervalSeconds = FirstIfNotEmpty(deployment?.schedules || null)?.readingIntervalInSeconds || 60 * 60 * 24 * 7;

        return {
            acquisitions:
                deployment?.schedules.map(s => {
                    return {
                        //we explicitly set this to undefined because we get chirp parameters when getting the acquisitions but we need to submit just an id to ensure a new object isn't created. - Simon
                        ...s.acquisitionParameters,
                        chirpParameters: undefined,
                        rdcAcquisitionChirpParameters: {
                            ...s.acquisitionParameters.rdcAcquisitionChirpParameters,
                            chirpParametersId: s.acquisitionParameters.rdcAcquisitionChirpParameters.chirpParameters.id
                        },
                        sensorId: s.acquisitionParameters.sensor.id
                    }
                }) || [],
            portParameters: deployment?.portParameters || [],
            intervalSeconds,
            selectedWirelessSettingPreset: (deployment !== null && wirelessSettingPresets !== undefined) ?
                this.findMatchingPreset(deployment?.wirelessSettings, wirelessSettingPresets) :
                undefined
        };
    }

    async componentDidMount() {
        const initialWirelessSettings = this.props.initialDeployment?.wirelessSettings;

        const wirelessSettingPresets = await Services.IoTDeployments.getWirelessSettingsPresets();
        this.setState({
            wirelessSettingPresets
        });

        //if (initialWirelessSettings !== undefined) {
            this.setState({
                selectedWirelessSettingPreset: this.findMatchingPreset(initialWirelessSettings, wirelessSettingPresets)
            });
        //}
    }

    render() {
        const { wirelessSettingPresets, showAdvanced } = this.state;
        if (wirelessSettingPresets === undefined) return <p>Loading presets...</p>;

        console.log("portParameters", this.state.portParameters);

        return (
            <div style={{ width: 550 }}>
                <DropDownButton<Devicedeployments>
                    options={this.props.deviceDeployments.filter(d => d.latestDeployment !== undefined)}
                    label="Copy from device"
                    labelFor={d => d.latestDeployment?.locationTag || d.device.id}
                    optionSelected={option => {
                        if (option.latestDeployment) {
                            this.setState(this.getStateFromDeployment(option.latestDeployment, this.state.wirelessSettingPresets));
                        }
                    }}
                />

                <FormControlLabel
                    label={<div style={{ width: 200 }}>Location:</div>}
                    labelPlacement="start"
                    sx={{ marginBottom: 1 }}
                    control={
                        <TextField
                            type="text"
                            value={this.state.location}
                            sx={{ padding: "1px" }}
                            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => this.setState({ location: ev.target.value })}
                        />
                    }
                />
                <FormControlLabel
                    label={<div style={{ width: 200 }}>Measurement schedule:</div>}
                    labelPlacement="start"
                    sx={{ marginBottom: 1 }}
                    control={
                        <IntervalPicker
                            valueSeconds={this.state.intervalSeconds}
                            onChange={newValueSeconds => this.setState({ intervalSeconds: newValueSeconds })}
                        />
                    }
                />

                {showAdvanced ?
                    <>
                        <FormControlLabel
                            label={<div style={{ width: 200 }}>Wireless mode:</div>}
                            labelPlacement="start"
                            sx={{ marginBottom: 1 }}
                            control={
                                <DropDownListControlled<Wirelesssettingspreset>
                                    options={wirelessSettingPresets}
                                    labelFor={x => x.name}
                                    selectedOption={this.state.selectedWirelessSettingPreset}
                                    onChange={x => this.setState({ selectedWirelessSettingPreset: x })}
                                />
                            }
                        />
                    </>
                    : <div style={{ textAlign: "center" }} hidden={!Services.userHasPolicy("ViewRdcRangeOptions")}>
                        <Link href="#" onClick={() => this.setState({ showAdvanced: true })}>Show advanced</Link>
                    </div>
                }
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Port</TableCell>
                                <TableCell>Sensor</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.acquisitions()}
                        </TableBody>
                    </Table>
                </TableContainer>

                <div style={{ marginTop: 10 }}>
                    {this.submitButton()}
                </div>
                <SensorSelectModalPanel
                    shouldBeShown={this.state.sensorSelectModalPanelShown}
                    onClose={() => this.setState({ sensorSelectModalPanelShown: false })}
                    onSensorSelect={sensors => this.sensorSelected(sensors[0])}
                    rootSensorGroup={this.props.rootSensorGroup}
                />
            </div>
        );
    }

    private submitButton() {
        const { initialDeployment } = this.props;
        const { selectedWirelessSettingPreset, location, intervalSeconds } = this.state;

        if (selectedWirelessSettingPreset === undefined) {
            return <>
                <Button
                    label="Create"
                    onClick={() => null}
                    isDisabled={true}
                />
                <p style={{ color: "red" }}>Wireless settings must be set</p>
            </>;
        }

        if (location === undefined) {
            return <>
                <Button
                    label="Create"
                    onClick={() => null}
                    isDisabled={true}
                />
                <p style={{ color: "red" }}>Location tag must be set</p>
            </>;
        }

        if (intervalSeconds < 10*60) {
            return <>
                <Button
                    label="Create"
                    onClick={() => null}
                    isDisabled={true}
                />
                <p style={{ color: "red" }}>Interval cannot be less than 10 minutes</p>
            </>;
        }

        return <Button
            label="Update"
            precedence="primary"
            className="submitButton"
            onClick={() => this.props.onCreateClick(
                {
                    deviceId: this.props.deviceId,
                    wirelessSettings: selectedWirelessSettingPreset,
                    deployedBy: initialDeployment?.deployedBy || "SB", // TODO: Get from user
                    deployedFrom: dayjs(new Date().toDateString()).utc(true).toDate(), // TODO: Use attempt log for this?
                    deployedUntil: initialDeployment?.deployedUntil || null,
                    deviceDescription: initialDeployment?.deviceDescription,
                    notes: initialDeployment?.notes || "No notes",
                    portParameters: this.state.portParameters,
                    schedules: this.state.acquisitions.map(a => {
                        return {
                            acquisitionParameters: {
                                ...a,
                                physicalMemoryIndex: a.sendPort
                            },
                            startTime: dayjs(new Date().toDateString()).utc(true).toDate(),
                            endTime: new Date("2050-01-01T10:00:00"),
                            physicalMemoryIndex: a.sendPort,
                            readingIntervalInSeconds: this.state.intervalSeconds,
                            deviceId: this.props.deviceId
                        }
                    }),
                    locationTag: this.state.location
                })}
        />;
    }

    private sensorSelected(sensor: Sensor | undefined) {
        const { selectingSensorForPort, acquisitions, portParameters } = this.state;
        if (selectingSensorForPort === null) throw new Error("Sensor not defined");

        // Remove existing acquisition if it exists
        const acquisitionsNew = acquisitions.filter(a => a.receivePort !== selectingSensorForPort);

        // Remove existing port parameter if it exists
        // TODO: Handle separate send and receive ports
        const portParametersNew = portParameters.filter(a => a.portNumber !== selectingSensorForPort);

        console.log("the sensor", sensor);

        if (sensor !== undefined) {

            const normalThicknessInM = sensor.structureNominalThicknessInMetres;
            const samplingFrequencyInKHz = 25000;
            const samplingFrequencyInHz = samplingFrequencyInKHz * 1000;

            let dataCompressionTrimLength = 2361;
            if (normalThicknessInM !== null && normalThicknessInM !== undefined
                && sensor.sensorType.ultrasonicWaveMode !== undefined) {
                const minSamplesToStore = 500;
                const velocityInMetresPerSecond = sensor.sensorType.ultrasonicWaveMode === Wavemode.Longitudinal
                    ? sensor.structureMaterial.longitudinalVelocityAtRoomTemperatureInMetresPerSecond
                    : sensor.structureMaterial.shearVelocityAtRoomTemperatureInMetresPerSecond;
                const calculatedTrimLength = Math.ceil(5 * normalThicknessInM * samplingFrequencyInHz / velocityInMetresPerSecond);
                dataCompressionTrimLength = Math.max(Math.min(2000, calculatedTrimLength), minSamplesToStore);
            }

           const acquisition: Acquisitionparameterspost = {
                dataCompressionBitDepth: 24,
                dataCompressionSubsampleRatio: 1,
                dataCompressionTrimLength,
                rdcAcquisitionChirpParameters: {
                    physicalMemoryIndex: selectingSensorForPort,
                    chirpParametersId: (sensor.useOverrideChirpParameters && sensor.sensorType.overrideChirpParameters != undefined) ? sensor.sensorType.overrideChirpParameters.id : sensor.sensorType.chirpParameters.id
                },
                thermocoupleEnabled: true,
                sendPort: selectingSensorForPort,
                receivePort: selectingSensorForPort,
                sensorId: sensor.id,
                physicalMemoryIndex: selectingSensorForPort
            };
            this.removeAcquisition(selectingSensorForPort);
            acquisitionsNew.push(acquisition);

            const portParameters: Portparameterspost = {
                calibrationDelayInPicoseconds: -177310, // TODO: Shani to find calibration delay for S5R for me to use
                coilCode: 1,
                deviceId: this.props.deviceId,
                physicalMemoryIndex: selectingSensorForPort,
                portNumber: selectingSensorForPort
            };
            this.removePortParameters(selectingSensorForPort);
            portParametersNew.push(portParameters);
        }

        this.setState({
            acquisitions: acquisitionsNew.sort((a, b) => a.sendPort - b.sendPort),
            portParameters: portParametersNew.sort((a, b) => a.portNumber - b.portNumber)
        });
    }

    private showSensor(sensorId: string, port: number, sensorGroup: SensorGroup): React.JSX.Element | null {
        const isDuplicate = this.state.acquisitions.filter(a => a.sensorId === sensorId).length > 1;

        const duplicateWarning = <>&nbsp;-&nbsp;
            <span style={{ verticalAlign: "middle" }}><WarningIcon fontSize="small" /></span>
            <span style={{ color: "orange" }}>&nbsp;duplicate</span>
        </>;

        // TODO: Separate finding sensor from rendering?
        for (const sensor of sensorGroup.sensors || []) {
            if (sensorId === sensor.id) {
                return <>
                    <span>{sensor.description}{isDuplicate ? duplicateWarning : null}</span><br />
                    <span>{sensor.rfid || "No RFID"}</span><br />
                    <span><Link href="#" onClick={() => this.onSelectSensorButtonClick(port)}>change</Link></span>
                </>;
            }
        }

        for (const subGroup of sensorGroup.children || []) {
            const subGroupResult = this.showSensor(sensorId, port, subGroup);
            if (subGroupResult !== null) return subGroupResult;
        }

        return null;
    }

    private acquisitions() {
        const { acquisitions } = this.state;
        if (acquisitions === undefined) return (null);

        const ports = [...Array(8).keys()];
        return ports.map(port => {
            const portAcquisitions = [...acquisitions.values()].filter(a => a.sendPort === port);

            return <TableRow key={port}>
                <TableCell>
                    {port + 1}
                </TableCell>

                {
                    portAcquisitions.length > 0 ?
                        <>
                            <TableCell>
                                {this.showSensor(portAcquisitions[0].sensorId, port, this.props.rootSensorGroup)}
                            </TableCell>
                            <TableCell>
                                <DeleteIconButton
                                    onClick={() => this.removeAcquisition(port)}
                                    size="small"
                                />
                            </TableCell>
                        </>
                        :
                        <>
                            <TableCell>
                                <Link href="#" onClick={() => this.onSelectSensorButtonClick(port)}>add</Link>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </>
                }
            </TableRow>
        });
    }

    private removeAcquisition(port: number) {
        const { acquisitions } = this.state;

        const acquisitionsNew = acquisitions.filter(a => a.receivePort !== port);
        this.setState({ acquisitions: acquisitionsNew });
    }

    private removePortParameters(port: number) {
        const { portParameters } = this.state;

        const portParametersNew = portParameters.filter(a => a.portNumber !== port);
        this.setState({ portParameters: portParametersNew });
    }

    private onSelectSensorButtonClick(port: number) {
        this.setState({
            sensorSelectModalPanelShown: true,
            selectingSensorForPort: port
        });
    }
}
