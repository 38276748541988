import "Styling/GlobalStyles.css";
import * as React from "react";
import { Component, CSSProperties } from "react";
import { createRoot } from "react-dom/client";
import AppConfiguration from "AppConfiguration";
import FailedRequestModalPanel from "Panels/Composite/Modals/FailedRequestModalPanel";
import TopLevelView from "Views/TopLevelView";
//import { init, Replay } from "@sentry/electron/renderer";
//import { init as reactInit } from "@sentry/react";

/*init({
    dsn: "https://b4762e9a8d304c38ea1e19fcb1fbecf1@o4505640024276992.ingest.sentry.io/4505640026570752",
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        new Replay({
            // Additional SDK configuration goes in here, for example:
            maskAllText: false,
            blockAllMedia: true,
        }),
    ],
    reactInit
}); Sentry code commented out until errors can be fixed */
const appWithSplashScreenStyle: CSSProperties = { height: "100%" };

interface AppState {
    appContentIsReady: boolean;
    appFailedToAuthenticate: boolean;
    splashAnimationIsComplete: boolean;
    failedToAuthenticateResponse: Response | null;
}

export default class App extends Component<{}, AppState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            appContentIsReady: false,
            appFailedToAuthenticate: false,
            splashAnimationIsComplete: false,
            failedToAuthenticateResponse: null
        };
    }

    render() {
        return AppConfiguration.skipSplashScreen
            ? this.app()
            : this.appWithSplashScreen();
    }

    private app() {
        return (
            <>
                {this.topLevelView()}
                {this.failedToAuthenticateModal()}
            </>
        );
    }

    private appWithSplashScreen() {
        return (
            <div style={appWithSplashScreenStyle}>
                {this.failedToAuthenticateModal()}
            </div>
        );
    }

    private topLevelView() {
        return (
            <TopLevelView
                onReadyToRender={() => this.onAppContentReady()}
                onFailedToAuthenticate={(response: Response) => this.onAppFailedToAuthenticate(response)}
            />
        );
    }

    private failedToAuthenticateModal() {
        const { appFailedToAuthenticate, failedToAuthenticateResponse } = this.state;

        return (
            <FailedRequestModalPanel
                message="Failed to authenticate"
                response={failedToAuthenticateResponse}
                shouldBeShown={appFailedToAuthenticate}
            />
        );
    }

    private onAppContentReady() {
        this.setState({ appContentIsReady: true });
    }

    private onAppFailedToAuthenticate(response: Response) {
        this.setState({
            appFailedToAuthenticate: true,
            failedToAuthenticateResponse: response
        });
    }
}

const root = createRoot(document.getElementById("root")!);
root.render(<App />)