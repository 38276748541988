import * as React from "react";
import { Component } from "react";
// import ModalPanel from "Panels/ModalPanel";
import LoginForm from "../../../Forms/Composite/LoginForm";
import View from "Types/View";
import { ManifestType } from "Views/manifest";
import "./loginFormModalPanel.css"

interface LoginFormModalPanelProps {
    shouldBeShown: boolean;
    showWorkOffline?: boolean;
    onRequestOfflineView(view: View): void;
    onLoginClick(refreshToken: string, tenantName: string, availableTenants: string[], rememberMe: boolean): void;
    errorMessage?: string;
    allowLogin: boolean;
    onTenantChosen?(tenant: string): void;
    manifest?: ManifestType;
}

const prefixCls = "login-page"

export default class LoginFormModalPanel extends Component<LoginFormModalPanelProps, {}> {
    constructor(props: LoginFormModalPanelProps) {
        super(props);
        this.state = { isSaving: false };
    }



    render() {
        return <div className={`${prefixCls}`}>
            <video
                id="479c9658-b4da-17cc-4171-29c615c074f9-video"
                loop
                autoPlay
                style={{objectFit: "fill",height:"100vh",width:"100%"}}
                muted
                data-wf-ignore="true"
            >
                <source
                    src="background.webm"
                    data-wf-ignore="true" />
            </video>
            <img className="login-logo" src="logo.svg"  alt="Inductosense logo" />
            {this.form()}
        </div>
    }

    private form() {
        const { errorMessage, allowLogin, onTenantChosen, manifest } = this.props;

        return (
            <LoginForm
                showWorkOffline={this.props.showWorkOffline}
                onLoginClick={this.props.onLoginClick.bind(this)}
                onRequestOfflineView={this.props.onRequestOfflineView.bind(this)}
                errorMessage={errorMessage}
                allowLogin={allowLogin}
                onTenantChosen={onTenantChosen}
                manifest={manifest}
            />
        );
    }
}
