import User from "Model/User";
import Sensor from "Model/Sensor";
import sleep from "Utilities/Sleep";
import { Observable } from "rxjs";
import SampleReading from "../SampleReading";
import SubSeaConfig from "RRC/RRCConfig";
import SubSeaSensor from "RRC/RRCSensor";
import DeviceInfo from "Model/DeviceInfo";
import SocketConnection from "RRC/SocketConnection";
import SerialConnection from "RRC/SerialConnection";
import SpiderVoltages from "../../Spider/SpiderVoltages";

const simulateWandErrors = false;

export default class WandConnectionService {
    getDeviceSetupInfo(): Promise<{ serialNumber: number; calibrationValue: number }> {
        return window.mainCgiApi?.send("getDeviceSetupInfo");
    }

    getDeviceInfo(): Promise<DeviceInfo> {
        return window.mainCgiApi?.send("getDeviceInfo");
    }

    getDeviceInfoRRC(): Promise<DeviceInfo> {
        return window.mainCgiApi?.send("getDeviceInfoRRC");
    }

    getSystemDelayInSeconds(): Promise<number> {
        return window.mainCgiApi?.send("getSystemDelayInSeconds");
    }

    setSystemDelayInSeconds(systemDelayInSeconds: number): Promise<void> {
        return window.mainCgiApi?.send("setSystemDelayInSeconds", systemDelayInSeconds);
    }

    getAllUsers(): Promise<User[]> {
        return window.mainCgiApi?.send("getAllUsers");
    }

    async createUser(user: User) {
        return window.mainCgiApi?.send("createUser", user);
    }

    async deleteUser(index: number) {
        return window.mainCgiApi?.send("deleteUser", index);
    }

    getAllSensors(): Promise<Sensor[]> {
        return window.mainCgiApi?.send("getAllSensors");
    }

    async createSensor(sensor: Sensor) {
        return window.mainCgiApi?.send("createSensor", sensor);
    }

    async deleteSensor(index: number) {
        return window.mainCgiApi?.send("deleteSensor", index);
    }

    async getNumReadings() {
        return window.mainCgiApi?.send("getNumReadings");
    }

    getAllReadings() {
        const observable = new Observable<typeof SampleReading>(subscriber => {
            window.mainCgiApi?.send("getAllReadings").then(() => {
                subscriber.complete();
            });
        });
        return observable;
    }

    async listReadingFiles(): Promise<string[]> {
        return window.mainCgiApi?.send("listReadingFiles", {});
    }

    async retrieveReadingFile(filename: string): Promise<{}> {
        return window.mainCgiApi?.send("retrieveReadingFile", filename);
    }

    async deleteAllReadings() {
        await sleep(1500);

        if (Math.random() > 0.5 && simulateWandErrors) {
            throw Error("deletion failed");
        }
    }

    async dispose() {
        return window.mainCgiApi?.send("dispose");
    }

    async connect(details: SocketConnection | SerialConnection): Promise<boolean> {
        if (Object.prototype.hasOwnProperty.call(details, "socketIp")) {
            console.log("connectSocket", details);
            return window.mainCgiApi?.send("connectSocket", details);
        } else {
            console.log("connectSerial", details);
            return window.mainCgiApi?.send("connectSerial", details);
        }
    }

    async RRCGetConfig(): Promise<SubSeaConfig | null> {
        return window.mainCgiApi?.send("RRCGetConfig");
    }

    async RRCCreateDirectory(): Promise<void> {
        return window.mainCgiApi?.send("RRCCreateDirectory");
    }

    async RRCOpenDirectory(): Promise<void> {
        return window.mainCgiApi?.send("RRCOpenDirectory");
    }

    async SpiderCreateDirectory(): Promise<void> {
        return window.mainCgiApi?.send("SpiderCreateDirectory");
    }

    SpiderGetVoltages(details: SocketConnection): Promise<SpiderVoltages> {
        return window.mainCgiApi?.send("SpiderGetVoltages", details);
    }

    SpiderScanRfid(details: SocketConnection): Promise<{}> {
        return window.mainCgiApi?.send("SpiderScanRfid", details);
    }

    SpiderTakeGetReading(details: SocketConnection): Promise<{}> {
        return window.mainCgiApi?.send("SpiderTakeGetReading", details);
    }

    RRCStartAveraging(): Promise<boolean> {
        return window.mainCgiApi?.send("RRCStartAveraging");
    }

    RRCStopAveraging(): Promise<boolean> {
        return window.mainCgiApi?.send("RRCStopAveraging");
    }

    async RRCDoScan(): Promise<boolean> {
        const t0 = performance.now();
        const result = await window.mainCgiApi?.send<boolean>("RRCDoScan");
        const t1 = performance.now();
        console.log("RRCDoScan", `${t1 - t0}ms`);
        return result;
    }

    async RRCGetMeasurement(noSamples: number): Promise<RRCMeasurement> {
        const t0 = performance.now();
        const result = await window.mainCgiApi?.send<RRCMeasurement>("RRCGetMeasurement", { noSamples, maximumSamplesPerSection: 3_400 });
        const t1 = performance.now();
        console.log("RRCGetMeasurement", noSamples, `${t1 - t0}ms`);
        return result;
    }

    async RRCSaveReading(
        payload: number[],
        payloadTime: { t: number; y: number }[],
        thicknessMm: number,
        deviceSerialNumber: number,
        sensor: SubSeaSensor,
        minThicknessMm: number,
        averageCount: number,
        systemDelaySeconds: number): Promise<{}> {
        return window.mainCgiApi?.send("RRCSaveReading", { payload, payloadTime, thicknessMm, deviceSerialNumber, sensor, minThicknessMm, averageCount, systemDelaySeconds });
    }

    async SpiderSaveReading(
        payload: number[],
        payloadTime: { t: number; y: number }[],
        thicknessMm: number,
        deviceSerialNumber: number,
        sensor: SubSeaSensor,
        minThicknessMm: number,
        averageCount: number,
        systemDelaySeconds: number): Promise<{}> {
        return window.mainCgiApi?.send("SpiderSaveReading", { payload, payloadTime, thicknessMm, deviceSerialNumber, sensor, minThicknessMm, averageCount, systemDelaySeconds });
    }
}

interface RRCMeasurement {
    cumulativeAverages: number
    payload: number[]
    error: string
}
