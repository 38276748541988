import AuthenticationService from "Services/Platform/AuthenticationService";
import DevicesService from "Services/Platform/DevicesService";
import MaterialsService from "Services/Platform/MaterialsService";
import GatewaysService from "Services/Platform/GatewaysService";
import PayloadsService from "Services/Platform/PayloadsService";
import ReadingsService from "Services/Platform/ReadingsService";
import SensorGroupsService from "Services/Platform/SensorGroupsService";
import SensorsService from "Services/Platform/SensorsService";
import ServiceGeneralError from "Services/Platform/ServiceGeneralError";
import ServiceTimeoutError from "Services/Platform/ServiceTimeoutError";
import UiBlockingState from "Types/UiBlockingState";
import IoTDeploymentsService from "Services/Platform/IoTDeploymentsService";
import SensorReadingsService from "./SensorReadingsService";
import UsersService from "./UsersService";
import DcUsersService from "./DcUsersService";
import SyncService from "./SyncService";
import DcDeploymentsService from "./DcDeploymentsService";
import DataUploadService from "./DataUploadService";
import { AuthenticationState } from "./AuthenticationStateMachine";
import CacheService from "./CacheService";
declare const __VERSION__: string;
declare const __UPDATE_CHANNEL__: "staging" | "production" | "none";

class ApiServices
{
    private authenticationService!: AuthenticationService;
    public Cache!: CacheService;
    public Devices!: DevicesService;
    public IoTDeployments!: IoTDeploymentsService;
    public DcDeployments!: DcDeploymentsService;
    public Materials!: MaterialsService;
    public Payloads!: PayloadsService;
    public Readings!: ReadingsService;
    public SensorGroups!: SensorGroupsService;
    public SensorReadingsService!: SensorReadingsService;
    public Sensors!: SensorsService;
    public UsersService!: UsersService;
    public DataUploadService!: DataUploadService;
    public DcUsersService!: DcUsersService;
    public SyncService!: SyncService;
    public Gateways!: GatewaysService;
    private setUiBlocking!: (blockingState: UiBlockingState) => void;
    public LocalDbName?: string;

    public getSavedToken() {
        return localStorage.getItem("latestToken");
    }

    public isTokenSet(): boolean {
        return localStorage.getItem("latestToken") !== null;
    }

    public async initialiseAuthentication() {
        this.authenticationService = await AuthenticationService.create(await this.getUrlPrefix(), this.getTenant());
    }

    public async completeAuthentication(refreshToken: string, tenantName: string, rememberMe: boolean) {

        this.authenticationService.setRefreshToken(refreshToken);
        this.authenticationService.setTenant(tenantName);

        if (rememberMe) {
            localStorage.setItem("latestToken", refreshToken); // TODO: Need this?
            localStorage.setItem("tenant", tenantName);
        }

        if (this.authenticationService === null || this.authenticationService === undefined) {
             this.authenticationService = await AuthenticationService.create(await this.getUrlPrefix(), tenantName);
        }
        this.authenticationService.initialise();
        await this.startServices();
    }

    public async getRefreshTokenFromServer(username: string, password: string, rememberMe: boolean) {
        // TODO: UI blocking not needed?
        return this.authenticationService.getRefreshTokenFromServer(username, password, rememberMe);
    }

    public async authenticationFederatedPost(code: string, redirectUri: string) {
        // TODO: UI blocking not needed?
        return this.authenticationService.authenticationFederatedPost(code, redirectUri);
    }

    public async getAuthenticationTokenRefresh() {
        return this.authenticationService.getAuthenticationTokenRefresh();
    }

    public async waitForAuthenticated() {
        await this.authenticationService.waitForState(AuthenticationState.Authenticated, 10000);
    }

    public refresh() {
        return this.authenticationService.refresh();
    }

    public refreshAccessToken(tenant: string) {
        return this.authenticationService.refreshAccessToken(tenant);
    }

    public clearTokens() {
        // TODO: Do local ones?
        localStorage.removeItem("latestToken");
        localStorage.removeItem("tenant");
    }

    public async startServices() {
        try
        {
            if (this.authenticationService === null || this.authenticationService === undefined) {
                throw Error;
            }

            await this.authenticationService.waitForState(AuthenticationState.Authenticated, 10000);
            console.log("The service is authenticated!");
            // Now we're sure that the service is authenticated, so we start services.

            const state = await this.authenticationService.getCurrentAuthState();
            // Check if the user is authenticated before starting services
            if (state === AuthenticationState.Authenticated) {

                this.Cache = new CacheService(this.authenticationService);
                this.Devices = new DevicesService(this.authenticationService);
                this.IoTDeployments = new IoTDeploymentsService(this.authenticationService);
                this.DcDeployments = new DcDeploymentsService(this.authenticationService);
                this.Materials = new MaterialsService(this.authenticationService);
                this.Payloads = new PayloadsService(this.authenticationService);
                this.Readings = new ReadingsService(this.authenticationService);
                this.SensorGroups = new SensorGroupsService(this.authenticationService);
                this.SensorReadingsService = new SensorReadingsService(this.authenticationService);
                this.Sensors = new SensorsService(this.authenticationService);
                this.UsersService = new UsersService(this.authenticationService);
                this.DataUploadService = new DataUploadService(this.authenticationService);
                this.DcUsersService = new DcUsersService(this.authenticationService);
                this.SyncService = new SyncService(this.authenticationService);
                this.Gateways = new GatewaysService(this.authenticationService);

                // Optionally, you might check if the token works or handle other service-startup logic here.
                //await this.SensorGroups.getRootSensorGroup();
            } else {
                console.error("Cannot start services, user is not authenticated");
            }
        }
        catch (err)
        {
            console.error("Failed to authenticate", err);
        }
    }

    //public StopServices(): void {
    //    // Optionally: Call a method on each service to clean up any ongoing operations
    //    // before setting them to undefined.
    //    this.callDisposeOnService(this.Devices);
    //    this.callDisposeOnService(this.IoTDeployments);
    //    this.callDisposeOnService(this.DcDeployments);
    //    this.callDisposeOnService(this.Materials);
    //    this.callDisposeOnService(this.Payloads);
    //    this.callDisposeOnService(this.Readings);
    //    this.callDisposeOnService(this.SensorGroups);
    //    this.callDisposeOnService(this.SensorReadingsService);
    //    this.callDisposeOnService(this.Sensors);
    //    this.callDisposeOnService(this.UsersService);
    //    this.callDisposeOnService(this.DataUploadService);
    //    this.callDisposeOnService(this.DcUsersService);
    //    this.callDisposeOnService(this.SyncService);
    //    this.callDisposeOnService(this.Gateways);

    //    // Set each service to undefined to stop them.
    //    this.Devices = undefined;
    //    this.IoTDeployments = undefined;
    //    this.DcDeployments = undefined;
    //    this.Materials = undefined;
    //    this.Payloads = undefined;
    //    this.Readings = undefined;
    //    this.SensorGroups = undefined;
    //    this.SensorReadingsService = undefined;
    //    this.Sensors = undefined;
    //    this.UsersService = undefined;
    //    this.DataUploadService = undefined;
    //    this.DcUsersService = undefined;
    //    this.SyncService = undefined;
    //    this.Gateways = undefined;
    //}

    //private callDisposeOnService(service: any): void {
    //    // If the service has a dispose method, call it.
    //    service?.dispose?.();
    //}

    public getCustomServerUrl(): string | undefined {
        return localStorage["customServerUrl"];
    }

    public async getUrlPrefix(): Promise<string> {
        const customUrl = this.getCustomServerUrl();
        if (customUrl) return customUrl;

        const channel = await this.getChannel();
        if (channel === "production" || localStorage["productionOverride"] === "true") return "https://api-production.inductosenseplatform.com/v1";
        if (channel === "staging") return "https://api-staging.inductosenseplatform.com/v1";
        return "http://localhost:3200/v1";  
    }

    public getChannel() {
        return __UPDATE_CHANNEL__;
    }

    public getVersion() { // TODO: Save non local storage
        return __VERSION__;
    }

    public getTenant(): string | null {
        if (this.authenticationService) {
            return this.authenticationService.getTenantName();
        } else {
            return this.getSavedTenant();
        }
    }

    public getSavedTenant(): string | null {
        return localStorage.getItem("tenant");
    }

    public async batch(batchOperations: () => Promise<void>, blockingState: UiBlockingState) {
        if (blockingState && this.setUiBlocking) this.setUiBlocking(true);
        try {
            await batchOperations();
        } catch (e)
        {
            if (e instanceof ServiceTimeoutError) {
                this.setUiBlocking("The operation has taken too long and may not have completed successfully. Please check your network connection and try again.");
            } else if (e instanceof ServiceGeneralError) {
                this.setUiBlocking("An error occurred. Please check your network connection and try again.");
            } else {
                this.setUiBlocking("An unexpected error has occurred. Please report this to the Inductosense support team.");
            }
            return;
        }
        if (blockingState) this.setUiBlocking(false);
    }

    public userHasPolicy(policyName: string) {
        return this.authenticationService.getPolicies().includes(policyName);
    }

    public getLatestToken() {
        if (!this.authenticationService) return null;
        return this.authenticationService.getLatestToken();
    }

    public getUsername() {
        return this.authenticationService.getUsername();
    }

    public getUiSettings() {
        return this.authenticationService.getUiSettings();
    }

    public isSessionActive(){
        if (!this.authenticationService) return null;
        return this.authenticationService.isSessionActive();
        
    }

    public isServerState(){
        return this.authenticationService.isServerState();
    }


    public setSessionActive(active: boolean): void {
        this.authenticationService.setSessionActive(active);
    }

    public ClearAuthenticationTokens(): void {
        this.authenticationService.ClearAuthenticationTokens();
    }

    public sendAliveMessage() {
        if (!this.authenticationService) return null;
        return this.authenticationService.postAliveMessage();
    }
}

function createServicesSingleton() {
    return new ApiServices();
}

const Services = createServicesSingleton();

export default Services;
