import * as React from "react";
import * as Unicode from "Constants/UnicodeCharacters";
import { Component, CSSProperties } from "react";
import { FormSection } from "Types/Forms";
import { FormField } from "Forms/FormField";
import { Material } from "@inductosense/typescript-fetch";
import FieldIsRequiredValidator from "Validation/Fields/FieldIsRequiredValidator";
import Form from "Forms/Form";
import SaveAndCancelButtonsFormFooter from "Forms/Shared/SaveAndCancelButtonsFormFooter";
import Services from "Services/Platform/Services";
import StringRepresentsAPositiveNumberOrNothingValidator from "Validation/Strings/StringRepresentsAPositiveNumberOrNothingValidator";
import StringRepresentsANegativeNumberOrNothingValidator from "Validation/Strings/StringRepresentsANegativeNumberOrNothingValidator";
import Validator from "Validation/Validator";
import UiSettings from "../../../Model/UiSettings";
import TextFieldControlled from "Components/Input/TextFieldControlled";
import ThermalCoefficientUnitsText from "Components/Text/ThermalCoefficientUnitsText";
import { perDegreeCToPerDegreeF, perDegreeFToPerDegreeC } from "../../../Utilities/Conversion";

const footerStyle: CSSProperties = {
    marginTop: 20,
    display: "flex",
    justifyContent: "flex-end"
};

interface CreateOrEditMaterialFormProps {
    material?: Material;
    onSave(): void;
    onSaveComplete(): void;
    onCancel(): void;
    uiSettings: UiSettings;
}

interface CreateOrEditMaterialFormState {
    fields: Map<string, FormField>;
    userTriedToSave: boolean;
    isSaving: boolean;
}

export default class CreateOrEditMaterialForm extends Component<CreateOrEditMaterialFormProps, CreateOrEditMaterialFormState> {
    private fieldsRequiredValidator: Validator<string>;
    private positiveNumberValidator: Validator<string>;
    private negativeNumberValidator: Validator<string>;

    constructor(props: CreateOrEditMaterialFormProps) {
        super(props);

        const { material } = props;

        this.fieldsRequiredValidator = new FieldIsRequiredValidator("This field is required");
        this.positiveNumberValidator = new StringRepresentsAPositiveNumberOrNothingValidator();
        this.negativeNumberValidator = new StringRepresentsANegativeNumberOrNothingValidator();

        this.state = {
            fields: new Map([
                ["name", new FormField(
                    material?.name ?? null,
                    [this.fieldsRequiredValidator]
                )],
                ["longitudinalVelocity", new FormField(
                    this.convertMetresPerSecondToUnits(material?.longitudinalVelocityAtRoomTemperatureInMetresPerSecond) ?? null,
                    [this.fieldsRequiredValidator, this.positiveNumberValidator]
                )],
                ["shearVelocity", new FormField(
                    this.convertMetresPerSecondToUnits(material?.shearVelocityAtRoomTemperatureInMetresPerSecond) ?? null,
                    [this.fieldsRequiredValidator, this.positiveNumberValidator]
                )],
                ["thermalCoefficient", new FormField(
                    this.convertPerDegreesCToUnits(material?.thermalExpansionCoefficientPerDegreeCelsius) ?? null,
                    [this.fieldsRequiredValidator, this.negativeNumberValidator]
                )]
            ]),
            userTriedToSave: false,
            isSaving: false
        };
    }

    render() {
        return (
            <Form
                sections={this.sections()}
                footer={this.footer()}
            />
        );
    }

    private sections() {
        return [
            this.nameSection(),
            this.longitudinalVelocitySection(),
            this.shearVelocitySection(),
            this.thermalExpansionCoefficientSection()
        ];
    }

    private footer() {
        return (
            <div style={footerStyle}>
                <SaveAndCancelButtonsFormFooter
                    isSaving={this.state.isSaving}
                    isValid={this.formValuesAreValid()}
                    onSaveButtonClick={() => this.onSaveButtonClick()}
                    onCancelButtonClick={() => this.onCancelButtonClick()}
                />
            </div>
        );
    }

    private nameSection(): FormSection {
        const fieldName = "name";
        const field = this.state.fields.get(fieldName);
        console.log("name validationError", field?.validators.filter(v => !v.isValid(field?.value!)).map(v => v.errorMessage).join(","));
        return {
            marginTop: 15,
            label: { text: "Name", isRequired: true, marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextFieldControlled
                        width={135}
                        value={field?.value || ""}
                        disabled={this.state.isSaving}
                        onChange={change => this.onValueChanged(fieldName, change.target.value)}
                        validationError={field?.validators.filter(v => !v.isValid(field?.value!)).map(v => v.errorMessage).join(",")}
                    />
                )
            }
        };
    }

    private longitudinalVelocitySection(): FormSection {
        const fieldName = "longitudinalVelocity";
        const field = this.state.fields.get(fieldName);
        return {
            marginTop: 15,
            label: { text: `Longitudinal velocity @${Unicode.NonBreakingSpace}${this.getTemperatureForVelocities()}`, isRequired: true, marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextFieldControlled
                        suffix={this.getVelocityUnits()}
                        width={135}
                        value={field?.value || ""}
                        disabled={this.state.isSaving}
                        validationError={field?.validators.filter(v => !v.isValid(field?.value!)).map(v => v.errorMessage).join(",")}
                        onChange={change => this.onValueChanged(fieldName, change.target.value)}
                    />)
            }
        };
    }

    private shearVelocitySection(): FormSection {
        const fieldName = "shearVelocity";
        const field = this.state.fields.get(fieldName);
        return {
            marginTop: 15,
            label: { text: `Shear velocity @${Unicode.NonBreakingSpace}${this.getTemperatureForVelocities()}`, isRequired: true, marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextFieldControlled
                        suffix={this.getVelocityUnits()}
                        width={135}
                        value={field?.value || ""}
                        disabled={this.state.isSaving}
                        validationError={field?.validators.filter(v => !v.isValid(field?.value!)).map(v => v.errorMessage).join(",")}
                        onChange={change => this.onValueChanged(fieldName, change.target.value)}
                    />
                )
            }
        };
    }

    private getTemperatureForVelocities() {
        if (this.props.uiSettings.temperatureUnits === "fahrenheit") {
            return `77${Unicode.NonBreakingSpace}${Unicode.Degrees}F`;
        } else {
            return `25${Unicode.NonBreakingSpace}${Unicode.Degrees}C`;
        }
    }

    private getVelocityUnits() {
        if (this.props.uiSettings.unitsMode === "imperial") {
            return "ft/s";
        } else {
            return "m/s";
        }
    }

    private convertUnitsToMetresPerSecond(input: string) {
        if (this.props.uiSettings.unitsMode === "imperial") {
            return parseFloat(input) / 3.281;
        } else {
            return parseFloat(input);
        }
    }

    private convertMetresPerSecondToUnits(input: number | undefined) {
        if (input === undefined) return undefined;

        if (this.props.uiSettings.unitsMode === "imperial") {
            return (input * 3.281).toString();
        } else {
            return input.toString();
        }
    }

    private convertPerDegreesCToUnits(input: number | undefined) {
        if (input === undefined) return undefined;

        if (this.props.uiSettings.temperatureUnits === "fahrenheit") {
            return (perDegreeCToPerDegreeF(input) * 10 ** 6).toFixed(2);
        } else {
            return (input * 10 ** 6).toFixed(2);
        }
    }

    private convertUnitsToPerDegreesC(input: string) {
        if (this.props.uiSettings.temperatureUnits === "fahrenheit") {
            return (perDegreeFToPerDegreeC(parseFloat(input) * 10 ** (-6)));
        } else {
            return parseFloat(input) * 10 ** (-6);
        }
    }

    private thermalExpansionCoefficientSection(): FormSection {
        const { temperatureUnits } = this.props.uiSettings;

        const fieldName = "thermalCoefficient";
        const field = this.state.fields.get(fieldName);
        return {
            marginTop: 15,
            label: { text: `Thermal coefficient ${Unicode.Alpha}${Unicode.NonBreakingSpace}`, isRequired: true, marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextFieldControlled
                        suffix={<ThermalCoefficientUnitsText temperatureUnits={temperatureUnits} />}
                        width={135}
                        value={field?.value || ""}
                        disabled={this.state.isSaving}
                        validationError={field?.validators.filter(v => !v.isValid(field?.value!)).map(v => v.errorMessage).join(",")}
                        onChange={change => this.onValueChanged(fieldName, change.target.value)}
                    />
                )
            }
        };
    }

    private async onSaveButtonClick() {
        if (!this.state.userTriedToSave) this.setState({ userTriedToSave: true });
        if (this.formValuesAreValid()) {
            this.props.onSave();
            this.setState({ isSaving: true });
            await this.saveForm();
        }
    }

    private onCancelButtonClick() {
        this.props.onCancel();
    }

    private onValueChanged(name: string, newValue: string | null) {
        const newFields = this.state.fields;
        const newField = newFields.get(name);
        newField!.value = newValue;
        newFields.set(name, newField!);
        this.setState({ fields: newFields });
    }

    private formValuesAreValid() {
        for (const f of this.state.fields.values()) {
            if (!f.isValid()) return false;
        }
        return true;
    }

    private async saveForm() {
        if (this.props.material) {
            await Services.Materials.updateMaterial(this.props.material.id, {
                isPreset: false,
                name: this.state.fields.get("name")!.value || undefined,
                longitudinalVelocityAtRoomTemperatureInMetresPerSecond: this.convertUnitsToMetresPerSecond(this.state.fields.get("longitudinalVelocity")!.value!)!,
                shearVelocityAtRoomTemperatureInMetresPerSecond: this.convertUnitsToMetresPerSecond(this.state.fields.get("shearVelocity")!.value!)!,
                thermalExpansionCoefficientPerDegreeCelsius: this.convertUnitsToPerDegreesC(this.state.fields.get("thermalCoefficient")!.value!),
                longitudinalTemperatureVelocityChangeInMetresPerSecondPerDegreeKelvin: this.props.material.longitudinalTemperatureVelocityChangeInMetresPerSecondPerDegreeKelvin,
                shearTemperatureVelocityChangeInMetresPerSecondPerDegreeKelvin: this.props.material.shearTemperatureVelocityChangeInMetresPerSecondPerDegreeKelvin
            });
        } else {
            await Services.Materials.createMaterial({
                isPreset: false,
                name: this.state.fields.get("name")!.value || undefined,
                longitudinalVelocityAtRoomTemperatureInMetresPerSecond: this.convertUnitsToMetresPerSecond(this.state.fields.get("longitudinalVelocity")!.value!)!,
                shearVelocityAtRoomTemperatureInMetresPerSecond: this.convertUnitsToMetresPerSecond(this.state.fields.get("shearVelocity")!.value!)!,
                thermalExpansionCoefficientPerDegreeCelsius: this.convertUnitsToPerDegreesC(this.state.fields.get("thermalCoefficient")!.value!),
                longitudinalTemperatureVelocityChangeInMetresPerSecondPerDegreeKelvin: 0,
                shearTemperatureVelocityChangeInMetresPerSecondPerDegreeKelvin: 0
            });
        }

        this.props.onSaveComplete();
    }
}
