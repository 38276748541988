import DcScanResponse from "DC/DcScanResponse";
import * as MainCGI from "Services/Electron/MainCGI";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const send: ((eventName: string, args?: any) => Promise<any>) | undefined = window.mainCgiApi?.send;
const receive: ((callback: (arg: { status: string }) => void) => void) = window.mainCgiApi?.receive;

export function isServiceAvailable() {
    return !!send;
}

export async function initWand(): Promise<boolean> {
    return send!("initWand", await MainCGI.getParams());
}

export async function disposeWand(): Promise<void> {
    return send!("disposeWand");
}

export async function findWand(): Promise<boolean> {
    return send!("findWand", await MainCGI.getParams());
}

export async function wandAllDevices(): Promise<[{}]> {
    return send!("wandAllDevices", await MainCGI.getParams());
}

export async function deleteAllReadings(): Promise<[{}]> {
    return send!("deleteAllReadings");
}

export async function getDeviceInfo(): Promise<DcScanResponse | null> { // TODO: Need to make function name match scan response
    return send!("getDeviceInfo", await MainCGI.getParams());
}

export function getDeviceSetupInfo(): Promise<{ serialNumber: number; calibrationValue: number } > {
    return send!("getDeviceSetupInfo");
}

export async function sync(): Promise<{ result: boolean; displayError: string; error: string }> {
    return send!("sync", await MainCGI.getParams());
}

export async function subscribeStatusUpdate(callback: (arg: { status: string }) => void) {
    return receive(callback);
}
