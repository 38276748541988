import SubSeaSensor from "RRC/RRCSensor";
import SocketConnection from "RRC/SocketConnection";
import SpiderVoltages from "../../Spider/SpiderVoltages";
import { send } from "./MainCGI";

interface SpiderReadingReponse {
    payload: number[];
    thicknessMm: number | null;
    minThicknessMm: number;
    systemDelayInSeconds: number;
    rfid: string;
    deviceSerialNumber: number;
}

export async function dispose() {
    return send("SpiderDispose");
}

export async function connect(details: SocketConnection): Promise<boolean> {
    return send("SpiderConnectSocket", details);
}

export async function SpiderCreateDirectory(): Promise <void> {
    return send("SpiderCreateDirectory");
}

export async function SpiderGetVoltages(details: SocketConnection): Promise<SpiderVoltages> {
    return send("SpiderGetVoltages", details);
}

export async function SpiderScanRfid(details: SocketConnection): Promise<string> {
    return send("SpiderScanRfid", details);
}

export async function SpiderTakeGetReading(details: SocketConnection): Promise<SpiderReadingReponse> {
    return send("SpiderTakeGetReading", details);
}

export async function SpiderTest(details: SocketConnection): Promise<string> {
    return send("SpiderTest", details);
}

export async function SpiderSaveReading(
    payload: number[],
    payloadTime: { t: number; y: number }[],
    thicknessMm: number,
    deviceSerialNumber: number,
    sensor: SubSeaSensor,
    minThicknessMm: number,
    averageCount: number,
    systemDelaySeconds: number): Promise<{}> {
    console.log("SpiderSaveReading");
    return send("SpiderSaveReading", { payload, payloadTime, thicknessMm, deviceSerialNumber, sensor, minThicknessMm, averageCount, systemDelaySeconds });
}
