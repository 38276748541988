import * as React from "react";
import { Component, CSSProperties } from "react";
import InductosenseEmblem from "Components/Graphics/Logos/InductosenseEmblem";
import merge from "Utilities/Merge";
import Table from "Components/Tables/Table";
import TableColumn from "Types/TableColumn";
import * as PhysicalRDCDevice from "Services/Electron/PhysicalRDCDevice";
import * as MainCGI from "Services/Electron/MainCGI";
import ScannedDevice from "RDC/ScannedDevice";
import ProgressBox from "./ProgressBox";
import HealingButton from "../Components/Buttons/Composite/HealingButton";
import ModalPanel from "../Panels/ModalPanel";
import Button from "../Components/Buttons/Button";
import SimplePanel from "../Panels/SimplePanel";
import CloseButton from "../Components/Buttons/Composite/CloseButton";
import SignalStrengthIcon from "../Components/Graphics/Icons/SignalStrengthIcon";
import CameraButton from "../Components/Buttons/Composite/CameraButton";
import ReadingsTakenModalPanel from "./ReadingsTakenModalPanel";
import SplitPanel from "../Panels/SplitPanel";
import { List, ListItem, ListItemText, Container, FormControlLabel } from "@mui/material";
import RelativeDate from "../Components/Text/RelativeDate";
import TakenReading from "../Model/TakenReading";
import UiSettings from "../Model/UiSettings";
import DeviceDbStatus from "./DeviceDbStatus";
import ScanResponse from "./ScanResponse";
import FlexibleAndFixedPanel from "../Panels/FlexibleAndFixedPanel";
import ButtonsFormFooter from "../Forms/Shared/ButtonsFormFooter";
import { SystemUpdateAlt } from "@mui/icons-material";
import IconButton from "../Components/Buttons/IconButton";
import DownloadDataButton from "../Components/Buttons/Composite/DownloadDataButton";
import UpdateConfigButton from "../Components/Buttons/Composite/UpdateConfigButton";
import DropDownListControlled from "../Components/Input/DropDownListControlled";

const tableContainerStyle: CSSProperties = {
    height: "100%",
    borderTop: "1px solid #e7e7e7",
    boxShadow: "inset -10px 0 5px 1px rgba(0, 0, 0, 0.15)"
};

const tableStyle: CSSProperties = {
    height: "100%",
    overflow: "auto"
};

const commonTableHeaderStyle: CSSProperties = {
    fontSize: "12pt",
    fontFamily: "lato",
    fontWeight: "bold"
};

const disabledTableHeaderStyle: CSSProperties = {
    cursor: "default",
    color: "darkgrey"
};

const rdcDeviceContainerStyle: CSSProperties = {
    display: "flex"
};

const rdcDeviceDetailsContainerStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    paddingLeft: "10px"
};

const rdcDeviceDetailsNameStyle: CSSProperties = {
    fontSize: "medium"
};

interface RDCScanningOverviewFormProps {
    onClose(): void;
    onDbUploadRequired(): Promise<void>;
    uiSettings: UiSettings;
}

interface RDCScanningOverviewFormState {
    scanResponse?: ScanResponse;
    allDevices?: DeviceDbStatus[];
    isImporting: boolean;
    progress?: number;
    progressText?: string;
    isDownloadingData: boolean;
    isUpdatingConfig: boolean;
    isShowingDbUpdateModal: boolean;
    isRecovering: boolean;
    isTakingAdHoc: boolean;
    isUpdatingFirmware: boolean;
    showReplugMessage: boolean;
    readingsTaken?: TakenReading[];
    isShowingReadingsTaken: boolean;
    firmwareUpdatePrompt?: { currentVersion: string; newVersion: string; firmwareType: string };
    deploymentUpdatePrompt?: { currentDeployment: string; newDeployment: string; deploymentUpdateAvailable: boolean };
    extractionOfferPrompt?: { offer: true };
    operationErrorMessage?: string;
    showOnlyDevice?: ScannedDevice;
}

export default class RDCScanningOverviewForm extends Component<RDCScanningOverviewFormProps, RDCScanningOverviewFormState> {
    private readonly tableColumns: TableColumn<ScannedDevice>[];

    constructor(props: RDCScanningOverviewFormProps) {
        super(props);

        this.state = {
            isImporting: false,
            isDownloadingData: false,
            isUpdatingConfig: false,
            isShowingDbUpdateModal: false,
            isRecovering: false,
            isTakingAdHoc: false,
            isUpdatingFirmware: false,
            progress: 0,
            showReplugMessage: false,
            isShowingReadingsTaken: false
        }

        this.tableColumns = [
            {
                id: "rdcDevice",
                minimumWidth: 132,
                titleRenderer: () => this.tableHeaderTitle("RDC Device"),
                cellRenderer: rdcDevice => this.rdcDeviceCell(rdcDevice)
            },
            {
                id: "signalStrength",
                minimumWidth: 132,
                titleRenderer: () => this.tableHeaderTitle("Signal Strength (RSSI)"),
                cellRenderer: rdcDevice => this.signalStrength(rdcDevice)
            },
            {
                id: "firmwareUpdate",
                minimumWidth: 60,
                titleRenderer: () => this.tableHeaderTitle("Firmware Update"),
                cellRenderer: rdcDevice => this.firmwareUpdateCell(rdcDevice)
            },
            {
                id: "adhoc",
                minimumWidth: 60,
                titleRenderer: () => this.tableHeaderTitle("Take Live Readings"),
                cellRenderer: rdcDevice => this.adhocCell(rdcDevice)
            },
            {
                id: "download",
                titleRenderer: () => this.tableHeaderTitle("Download Data"),
                cellRenderer: rdcDevice => this.downloadDataCell(rdcDevice)
            },
            {
                id: "update",
                titleRenderer: () => this.tableHeaderTitle("Update Configuration"),
                cellRenderer: rdcDevice => this.updateConfigurationCell(rdcDevice)
            },
            {
                id: "recover",
                titleRenderer: () => this.tableHeaderTitle("Recover Readings"),
                cellRenderer: rdcDevice => this.recoverCell(rdcDevice)
            }
        ];
    }

    signalStrength(rdcDevice: ScannedDevice) {
        return <SignalStrengthIcon rssi={rdcDevice.rssi}/>
    }

    async componentDidMount() {

        //prompt("Please enter serial"); WIP allowing device to be selected for USB connection

        const initResult = await PhysicalRDCDevice.initRdc();
        console.log("initResult", initResult);
        if (!initResult) {
            this.setState({ showReplugMessage: true });
            await PhysicalRDCDevice.findDongle();
            this.setState({ showReplugMessage: false });
            await PhysicalRDCDevice.initRdc();
        }

        MainCGI.subscribeStatusUpdate(args => {
            this.setState({ progressText: args.status });
        });

        PhysicalRDCDevice.subscribeUpdateOffers(arg => {
            this.setState({ firmwareUpdatePrompt: arg });
            return false;
        });

        PhysicalRDCDevice.subscribeExtractionOffers(() => {
            this.setState({ extractionOfferPrompt: { offer: true } });
            return false;
        });

        PhysicalRDCDevice.subscribeDeploymentUpdateOffers(arg => {
            this.setState({ deploymentUpdatePrompt: arg });
            return false;
        });

        this.setState({ allDevices: await PhysicalRDCDevice.allDevices() });

        await this.scanForDevices();
    }

    async componentWillUnmount() {
        await MainCGI.unsubscribeStatusUpdate();
        await PhysicalRDCDevice.disposeRdc();
    }

    async scanForDevices() {
        this.setState({ scanResponse: undefined, progress: 0 });

        let progress = 0;

        const interval = setInterval(() => {
            this.setState({ progress: progress += 1 });
        }, (30 * 1000) / 100);

        const scanResponse: ScanResponse = await PhysicalRDCDevice.scanForDevices();

        clearInterval(interval);
        
        this.setState({ scanResponse });
    }

    render() {
        return <>
            <SimplePanel
                title="RDC Scanning"
                titleAlignment="centre"
                actionButton={this.closeButton()}
                content={this.content()}
                shouldApplyPadding={false}
            />
        </>
    }

    private closeButton() {
        return <CloseButton onClick={() => this.props.onClose()} className="rdcScanningCloseButton" />;
    }

    content() {
        const { allDevices } = this.state;

        return <SplitPanel
            firstContent={this.scanForm()}
            secondContent={<div>
                    <h2>All devices</h2>

                    <List>
                    {allDevices?.map(d =>
                        <ListItem key={d.dbDeviceId}>
                            <ListItemText
                                primary={d.locationTag}
                                secondary={d.lastDownloadDateTime ?
                                    <span className={`lastDownloadText${d.macAddressHex}`}>Last download <RelativeDate date={d.lastDownloadDateTime} /></span>
                                    : null}
                            ></ListItemText>
                        </ListItem>
                    )}
                    </List>
            </div>}
            plane="horizontal"
            splitRatio={0.8}
        />
    }

    usbSelection() {
        const { scanResponse, showOnlyDevice } = this.state;
        if (!scanResponse) return null;

        return <div style={{ border: "solid 1px #eee", padding: 5 }}>
            <FormControlLabel
                label=<>Select USB device:&nbsp;</>
                labelPlacement="start"
                control={
                    <DropDownListControlled
                        selectedOption={showOnlyDevice}
                        options={scanResponse.results}
                        labelFor={r => r.macAddressHex + ", " + r.serialNumber}
                        onChange={d => this.setState({ showOnlyDevice: d })}
                    />
                }
            />
        </div>;
    }

    scanForm() {
        const { isDownloadingData, isUpdatingConfig, isRecovering, isTakingAdHoc, isUpdatingFirmware, scanResponse, showReplugMessage,
            progressText, readingsTaken, firmwareUpdatePrompt, operationErrorMessage, deploymentUpdatePrompt, extractionOfferPrompt } = this.state;

        if (showReplugMessage) {
            return <Container maxWidth="sm">
                <div style={{ backgroundColor: "#ddd", padding: 5 }}>
                    <p style={{ textAlign: "center" }}>Please plug in dongle/device (or unplug and plug it back in)</p>
                    <p style={{ textAlign: "center" }}>If this doesn&#39;t work please ensure key has been set</p>
                </div>
            </Container>;
        }

        return (
            <>
                <FlexibleAndFixedPanel
                    topFixedContent={scanResponse?.usbMode ? this.usbSelection() : undefined}
                    flexibleContent={scanResponse?.usbMode ? this.usbTable() : this.table()}
                    bottomFixedContent={
                        <div style={{ margin: 5 }}>
                            {scanResponse !== undefined ?
                                <Button label="Re-Scan" precedence="primary" onClick={this.onRescanClick.bind(this)} />
                                : <>&nbsp;</>}
                        </div>
                    }
                />
                <ModalPanel
                    shouldBeShown={isDownloadingData}
                    title="Downloading data"
                    content={<p>{progressText}</p>}
                />
                <ModalPanel
                    shouldBeShown={isUpdatingConfig}
                    title="Updating config"
                    content={<p>{progressText}</p>}
                />
                <ModalPanel
                    shouldBeShown={isRecovering}
                    title="Recover device data"
                    content={<p>{progressText}</p>}
                />
                <ModalPanel
                    shouldBeShown={isTakingAdHoc}
                    title="Taking live readings"
                    content={<p>{progressText}</p>}
                />
                <ModalPanel
                    shouldBeShown={isUpdatingFirmware}
                    title="Checking for firmware updates"
                    content={<p>{progressText}</p>}
                />
                {
                    operationErrorMessage !== undefined ?
                        <ModalPanel
                            shouldBeShown={true}
                            title="Operation Error"
                            content={<p className="operationErrorMessage">{operationErrorMessage}</p>}
                            onClose={() => this.setState({ operationErrorMessage: undefined })}
                        />
                    : null
                }
                {firmwareUpdatePrompt !== undefined ?
                    <ModalPanel
                        shouldBeShown={firmwareUpdatePrompt !== undefined}
                        title={`${firmwareUpdatePrompt.firmwareType} firmware`}
                        onClose={async () => {
                            await PhysicalRDCDevice.updateDevice(false);
                            this.setState({ firmwareUpdatePrompt: undefined })
                        }}
                        content={
                            firmwareUpdatePrompt.currentVersion !== firmwareUpdatePrompt.newVersion ?
                                <>
                                    <p>{firmwareUpdatePrompt.currentVersion} to {firmwareUpdatePrompt.newVersion} {firmwareUpdatePrompt.firmwareType}</p>
                                    <ButtonsFormFooter
                                        buttons={[
                                            <Button
                                                key="now"
                                                onClick={async () => {
                                                    this.setState({ firmwareUpdatePrompt: undefined });
                                                    await PhysicalRDCDevice.updateDevice(true);
                                                }}
                                                label="Update Now"
                                                precedence="primary"
                                            />,
                                            <Button
                                                key="later"
                                                onClick={async () => {
                                                    await PhysicalRDCDevice.updateDevice(false);
                                                    this.setState({ firmwareUpdatePrompt: undefined });
                                                }}
                                                label="Update Later"
                                            />
                                        ]}
                                    />
                                </> :
                                <>
                                    <p>You have the latest version: {firmwareUpdatePrompt.currentVersion}</p>
                                    <ButtonsFormFooter
                                        buttons={[
                                            <Button
                                                key="later"
                                                onClick={async () => {
                                                    await PhysicalRDCDevice.updateDevice(false);
                                                    this.setState({ firmwareUpdatePrompt: undefined });
                                                }}
                                                label="OK"
                                                precedence="primary"
                                            />,
                                            <Button
                                                key="now"
                                                onClick={async () => {
                                                    this.setState({ firmwareUpdatePrompt: undefined });
                                                    await PhysicalRDCDevice.updateDevice(true);
                                                }}
                                                label="Reinstall"
                                            />
                                        ]}
                                    />
                                </>
                        }
                    />
                    : null}
                {extractionOfferPrompt !== undefined ?
                    <ModalPanel
                        shouldBeShown={extractionOfferPrompt !== undefined}
                        title={"Extraction offer"}
                        onClose={async () => {
                            this.setState({ extractionOfferPrompt: undefined });
                            await PhysicalRDCDevice.updateDevice(false);
                        }}
                        content={
                            <>
                                <p>
                                    Readings have been found on the device which should be extracted to avoid problems with the
                                    firmware upgrade
                                </p>
                                <ButtonsFormFooter
                                    buttons={[
                                        <Button
                                            key="now"
                                            onClick={async () => {
                                                this.setState({ extractionOfferPrompt: undefined });
                                                await PhysicalRDCDevice.updateDevice(true);
                                            }}
                                            label="Extract readings"
                                            precedence="primary"
                                        />,
                                        <Button
                                            key="later"
                                            onClick={async () => {
                                                this.setState({ extractionOfferPrompt: undefined });
                                                await PhysicalRDCDevice.updateDevice(false);
                                            }}
                                            label="Don't extract"
                                        />
                                    ]}
                                />
                            </>
                        }
                    />
                    : null}
                {deploymentUpdatePrompt !== undefined ?
                    <ModalPanel
                        shouldBeShown={true}
                        title={"Update configuration?"}
                        onClose={async () => {
                            await PhysicalRDCDevice.updateDevice(false);
                            this.setState({ deploymentUpdatePrompt: undefined })
                        }}
                        content={
                            deploymentUpdatePrompt.deploymentUpdateAvailable ?
                                <>
                                    <p><strong>Current</strong></p>
                                    <p>{deploymentUpdatePrompt.currentDeployment}</p>

                                    <p><strong>New</strong></p>
                                    <p>{deploymentUpdatePrompt.newDeployment}</p>

                                    <ButtonsFormFooter
                                        buttons={[
                                            <Button
                                                key="now"
                                                onClick={async () => {
                                                    this.setState({ deploymentUpdatePrompt: undefined });
                                                    await PhysicalRDCDevice.updateDevice(true);
                                                }}
                                                label="Update Now"
                                                precedence="primary"
                                            />,
                                            <Button
                                                key="later"
                                                onClick={async () => {
                                                    await PhysicalRDCDevice.updateDevice(false);
                                                    this.setState({ deploymentUpdatePrompt: undefined });
                                                }}
                                                label="Update Later"
                                            />
                                        ]}
                                    />
                                </> :
                                <>
                                    <p>You have the latest configuration</p>
                                    <ButtonsFormFooter
                                        buttons={[
                                            <Button
                                                key="later"
                                                onClick={async () => {
                                                    await PhysicalRDCDevice.updateDevice(false);
                                                    this.setState({ deploymentUpdatePrompt: undefined });
                                                }}
                                                label="OK"
                                                precedence="primary"
                                            />
                                        ]}
                                    />
                                </>
                        }
                    />
                    : null}
                <ReadingsTakenModalPanel
                    onClose={() => this.setState({ readingsTaken: undefined })}
                    showReadingsTaken={readingsTaken}
                    unitsMode={this.props.uiSettings.unitsMode}
                />
            </>
        ); // TODO: Show readings taken modal using readingsTaken var?
    }

    private table() {
        const { scanResponse } = this.state;

        return (
            <div style={tableContainerStyle}>
                <div style={tableStyle}>
                    <Table
                        isScanRDc={true}
                        columns={this.tableColumns}
                        rows={scanResponse?.results || []}
                        getKey={row => row.macAddressHex}
                        noRowsMessage={scanResponse === undefined ? this.scanningMessage() : this.noDevicesMessage()}
                        minimumHeaderHeight={36}
                        shouldReduceRowHeight
                    />
                </div>
            </div>
        );
    }

    private usbTable() {
        const { showOnlyDevice } = this.state;
        if (!showOnlyDevice) return null;

        return (
            <div style={tableContainerStyle}>
                <div style={tableStyle}>
                    <Table
                        columns={this.tableColumns}
                        rows={[showOnlyDevice]}
                        getKey={row => row.macAddressHex}
                        minimumHeaderHeight={36}
                        shouldReduceRowHeight
                    />
                </div>
            </div>
        );
    }

    private noDevicesMessage() {
        return <p>No RDC devices found</p>;
    }

    private async onRescanClick() {
        await this.scanForDevices();
    }

    private scanningMessage() {
        const { progress, progressText } = this.state;

        return <ProgressBox progress={progress} progressText={progressText || "Scanning for devices..."} />;
    }

    private tableHeaderTitle(label: string) {
        return (
            <div style={merge(commonTableHeaderStyle, (Array.from(this.state.scanResponse?.results || [])).length === 0 ? disabledTableHeaderStyle : {})}>
                {label}
            </div>
        );
    }

    private rdcDeviceCell(rdcDevice: ScannedDevice) {
        const { allDevices } = this.state;
        const dbDevice = allDevices?.find(d => d.macAddressHex.substr(-12) == rdcDevice.macAddressHex.substr(-12));

        return (
            <div style={rdcDeviceContainerStyle}>
                <div><InductosenseEmblem width={55} height={55} /></div>
                <div style={rdcDeviceDetailsContainerStyle}>
                    <div style={rdcDeviceDetailsNameStyle}>{dbDevice?.locationTag}</div>
                    <div>{rdcDevice.macAddressHex}</div>
                    <div>{ dbDevice != undefined && dbDevice.lastDownloadDateTime
                        ? <>Last sync <RelativeDate date={dbDevice.lastDownloadDateTime} /></> : null}
                    </div>
                </div>
            </div>
        );
    }

    private firmwareUpdateCell(rdcDevice: ScannedDevice) {
        const { allDevices } = this.state;
        const dbDevice = allDevices?.find(d => d.macAddressHex === rdcDevice.macAddressHex);
        if (!dbDevice?.dbDeviceId) return null;

        return (
            <IconButton
                icon={<SystemUpdateAlt
                    color={"primary"}
                />}
                onClick={this.onFirmwareUpdateClick.bind(this, rdcDevice, dbDevice.dbDeviceId)}
            />
        );
    }

    private adhocCell(rdcDevice: ScannedDevice) {
        const { allDevices } = this.state;
        const dbDevice = allDevices?.find(d => d.macAddressHex === rdcDevice.macAddressHex);
        if (!dbDevice?.dbDeviceId) return null;

        return (
            <CameraButton
                color={"primary"}
                className={"adHocButton adHocButton" + rdcDevice.macAddressHex}
                isDisabled={false}
                onClick={this.onAdhocClick.bind(this, rdcDevice, dbDevice.dbDeviceId)}
            />
        );
    }

    private downloadDataCell(rdcDevice: ScannedDevice) {
        const { allDevices } = this.state;
        const dbDevice = allDevices?.find(d => d.macAddressHex === rdcDevice.macAddressHex);
        if (!dbDevice?.dbDeviceId) return null;

        return (
            <DownloadDataButton
                color={"primary"}
                className={"dataDownloadButton dataDownloadButton" + rdcDevice.macAddressHex}
                isDisabled={false}
                onClick={this.onDownloadDataClick.bind(this, rdcDevice, dbDevice.dbDeviceId)}
            />
        );
    }

    private updateConfigurationCell(rdcDevice: ScannedDevice) {
        const { allDevices } = this.state;
        const dbDevice = allDevices?.find(d => d.macAddressHex === rdcDevice.macAddressHex);
        if (!dbDevice?.dbDeviceId) return null;

        return (
            <UpdateConfigButton
                color={"primary"}
                className={"updateConfigButton updateConfigButton" + rdcDevice.macAddressHex}
                isDisabled={false}
                onClick={this.onUpdateConfigClick.bind(this, rdcDevice, dbDevice.dbDeviceId)}
            />
        );
    }

    private async onDownloadDataClick(rdcDevice: ScannedDevice, dbDeviceId: string) {
        this.setState({ isDownloadingData: true });

        const syncResult = await PhysicalRDCDevice.downloadDeviceData(rdcDevice.macAddressHex, dbDeviceId,
            rdcDevice.isLongRangeMode, rdcDevice.rssi, false);

        this.setState({
            isDownloadingData: false
        });

        await this.props.onDbUploadRequired(); // TODO: Switching back and forth issue
        console.log("syncResult", syncResult);

        if (!syncResult.result) {
            if (syncResult.connectionError) {
                this.setState({ operationErrorMessage: "Unable to connect to device. Reposition tablet/laptop according to user guide and try again" });
            } else {
                this.setState({ operationErrorMessage: "Sync failed. Please wait a minute and try again" });
            }

            return;
        }

        this.setState({ allDevices: await PhysicalRDCDevice.allDevices() });
    }

    private async onUpdateConfigClick(rdcDevice: ScannedDevice, dbDeviceId: string) {
        this.setState({ isUpdatingConfig: true });

        const syncResult = await PhysicalRDCDevice.updateDeviceConfig(rdcDevice.macAddressHex, dbDeviceId,
            rdcDevice.isLongRangeMode, rdcDevice.rssi, false);

        this.setState({
            isUpdatingConfig: false // TODO: Device config modal?
        });

        await this.props.onDbUploadRequired(); // TODO: Switching back and forth issue
        console.log("syncResult", syncResult);

        if (!syncResult.result) {
            if (syncResult.connectionError) {
                this.setState({ operationErrorMessage: "Unable to connect to device. Reposition tablet/laptop according to user guide and try again" });
            } else {
                this.setState({ operationErrorMessage: syncResult.errorMessage || "Sync failed. Please wait a minute and try again" });
            }

            return;
        }

        this.setState({ allDevices: await PhysicalRDCDevice.allDevices() });
    }

    private async onAdhocClick(rdcDevice: ScannedDevice, dbDeviceId: string) {
        this.setState({ isTakingAdHoc: true });

        const adhocResult = await PhysicalRDCDevice.triggerAdhoc(rdcDevice.macAddressHex, dbDeviceId,
            rdcDevice.isLongRangeMode, rdcDevice.rssi);

        await this.props.onDbUploadRequired();
        console.log("ad-hoc result", adhocResult);

        if (!adhocResult.result) {
            const details = adhocResult.errorMessage ? ("\n\n" + adhocResult.errorMessage) : "";

            if (adhocResult.connectionError) {
                this.setState({ operationErrorMessage: "Unable to connect to device. Reposition tablet/laptop and try again" + details });
            } else {
                this.setState({ operationErrorMessage: "Taking live readings failed" + details });
            }

            this.setState({ isTakingAdHoc: false }); // TODO: Move this to separate from db download?
            return;
        }

        this.setState({
            readingsTaken: adhocResult.readings,
            isShowingReadingsTaken: true,
            isTakingAdHoc: false
        });
    }

    private async onFirmwareUpdateClick(rdcDevice: ScannedDevice, dbDeviceId: string) {
        this.setState({ isUpdatingFirmware: true });

        const firmwareUpdateResult = await PhysicalRDCDevice.firmwareUpdate(rdcDevice.macAddressHex, dbDeviceId,
            rdcDevice.isLongRangeMode, rdcDevice.rssi); // TODO: Complete firmware update function

        await this.props.onDbUploadRequired();
        console.log("ad-hoc result", firmwareUpdateResult); // TODO: Need to log the firmware update?

        if (!firmwareUpdateResult.result) {
            const details = firmwareUpdateResult.errorMessage ? ("\n\n" + firmwareUpdateResult.errorMessage) : "";

            if (firmwareUpdateResult.connectionError) {
                this.setState({ operationErrorMessage: "Unable to connect to device. Reposition tablet/laptop and try again" + details });
            } else {
                this.setState({ operationErrorMessage: "Taking live readings failed" + details });
            }

            this.setState({ isTakingAdHoc: false });
            return;
        }

        this.setState({
            isUpdatingFirmware: false
        });
    }

    private recoverCell(rdcDevice: ScannedDevice) {
        const { allDevices } = this.state;
        const dbDevice = allDevices?.find(d => d.macAddressHex === rdcDevice.macAddressHex);
        if (!dbDevice?.dbDeviceId) return null;

        return (
            <HealingButton
                color={"primary"}
                isDisabled={false}
                onClick={this.onRecoverClick.bind(this, rdcDevice, dbDevice.dbDeviceId)}
            />
        );
    }

    private async onRecoverClick(rdcDevice: ScannedDevice, dbDeviceId: string) {
        this.setState({ isRecovering: true });

        await this.props.onDbUploadRequired();

        const recoverResult = await PhysicalRDCDevice.recoverDevice(rdcDevice.macAddressHex, dbDeviceId,
            rdcDevice.isLongRangeMode);
        if (!recoverResult) {
            this.setState({ operationErrorMessage: "Recover failed" });
        }

        this.setState({ isRecovering: false });
    }
}
