import * as React from "react";
import { Component } from "react";
import ModalPanel from "Panels/ModalPanel";
import AscanLineGraph from "../Components/Graphs/AscanLineGraph";
import { deserialiseBase64PayloadData } from "../Utilities/Deserialisation";
import TakenReading from "../Model/TakenReading";
import DisplacementText from "../Components/Text/DisplacementText";
import { ImageList, ImageListItem } from "@mui/material";
import { Endianness } from "@inductosense/typescript-fetch";

interface ReadingsTakenModalPanelProps {
    onClose(): void;
    showReadingsTaken?: TakenReading[];
    unitsMode: "metric" | "imperial";
}

export default class ReadingsTakenModalPanel extends Component<ReadingsTakenModalPanelProps> {
    constructor(props: ReadingsTakenModalPanelProps) {
        super(props);

        this.state = {};
    }

    componentDidUpdate(prevProps: ReadingsTakenModalPanelProps) {
        if (this.props.showReadingsTaken !== prevProps.showReadingsTaken
            && this.props.showReadingsTaken !== undefined && this.props.showReadingsTaken.length > 0) {
            this.setState({
                selectedReading: this.props.showReadingsTaken[0]
            });
        }
    }

    render() {
        const { showReadingsTaken, onClose } = this.props;

        return <ModalPanel
            title="Readings Taken"
            shouldBeShown={showReadingsTaken !== undefined}
            onClose={onClose}
            content={this.form()}
        />;
    }

    form() {
        const { showReadingsTaken } = this.props;
        // TODO: 3dp for inches, 2dp for mm

        return <div style={{ width: 650, height: 450 }}>
            {showReadingsTaken ? (
                <ImageList>
                    {showReadingsTaken?.map((r, index) => // TODO: Can gateLower be null?
                        <ImageListItem key={index}>
                            <div>Port {r.reading.metadata.pulsePortParameters.portNumber + 1}</div>
                            <div>{r.analysis !== null ? <>Thickness <DisplacementText tooltip={r.analysis.workings} className="thickness" displacementInMetres={r.analysis?.thickness} unitsMode={this.props.unitsMode} /></> : <>Thickness unknown</>}</div>
                            <AscanLineGraph
                                sensor={null}
                                comparisonSensor={null}
                                showModeButtons={false}
                                uiSettings={{ /* These aren't used anyway because that part of the component is hidden */
                                    unitsMode: "metric",
                                    temperatureUnits: "celsius"
                                }}
                                readingDetailed={{
                                    "payload": {
                                        "id": "7bc3b615-e157-4112-3bf7-08d99d499f23",
                                        "data": r.reading.payload,
                                        "format": "Base64",
                                        "samplingFrequencyInHertz": 25000000,
                                        "bitDepth": 24,
                                        "numberOfPresamples": 300,
                                        "endianness": Endianness.LittleEndian
                                    },
                                    "material": { // TODO: This is the problem in terms of thickness ** Maybe?
                                        "id": "e7cd66d4-84ef-4320-a933-e8344d3fc73e",
                                        "name": "Mild Steel",
                                        "created": new Date("2021-10-01T13:39:46.4307369Z"),
                                        "isPreset": true,
                                        "longitudinalVelocityAtRoomTemperatureInMetresPerSecond": 5900,
                                        "longitudinalTemperatureVelocityChangeInMetresPerSecondPerDegreeKelvin": -0.79,
                                        "shearVelocityAtRoomTemperatureInMetresPerSecond": 3200,
                                        "shearTemperatureVelocityChangeInMetresPerSecondPerDegreeKelvin": -0.3,
                                        "thermalExpansionCoefficientPerDegreeCelsius": 0,
                                        "deletedAt": null
                                    },
                                    "averageCount": 0,
                                    "sampleIntervalInSeconds": 3.999999975690116e-8,
                                    "velocityFromDeviceInMetresPerSecond": 0,
                                    "temperature": 19,
                                    "minimumThicknessInMillimetres": 0,
                                    "thicknessFromDeviceInMetres": 0,
                                    "userName": "simon",
                                    "created": new Date("2021-11-02T14:23:59.8374138Z"),
                                    "timestamp": new Date("2021-11-02T14:23:34Z"),
                                    "sensorId": "2b6b1835-ab82-4c2a-bbb6-633ea726c3cf",
                                    "deviceId": "2c6c3e07-0e88-42a0-b66b-a2b32096eb09",
                                    "id": "dec813dd-4db3-41c4-2a1d-08d99d499f23",
                                    "firmwareVersion": "",
                                    "locationTag": "Lab                             ",
                                    "calculatedEnvelopeSamples": [],
                                    "calculatedPeaks": []
                                }}
                                samples={deserialiseBase64PayloadData(r.reading.payload, 24)}
                                comparisonReading={null}
                                amplitudeThreshold={r.analysis !== null ? r.analysis.parameters.threshold : 100}
                                startTime={r.analysis?.parameters.gateLower !== undefined ? r.analysis.parameters.gateLower * 1e6 : 1}
                                endTime={r.analysis?.parameters.gateUpper !== undefined ? r.analysis.parameters.gateUpper * 1e6 : 20}
                                initialSize={{
                                    width: 200,
                                    height: 200
                                }}
                                compact
                            />
                        </ImageListItem>
                    )! /* TODO: Remove ! */}
                </ImageList>
            ): null}   
        </div>
    }
}
