import TakenReading from "../../Model/TakenReading";
import DeviceDbStatus from "../../RDC/DeviceDbStatus";
import ScanResponse from "../../RDC/ScanResponse";
import { send } from "Services/Electron/MainCGI";
import * as MainCGI from "Services/Electron/MainCGI";

const offerUpdate = window.mainCgiApi?.offerUpdate;
const removeOfferUpdateListeners = window.mainCgiApi?.removeOfferUpdateListeners;

const offerDeploymentUpdate = window.mainCgiApi?.offerDeploymentUpdate;
const offerExtraction = window.mainCgiApi?.offerExtraction;
//const removeOfferDeploymentUpdateListeners = window.mainCgiApi?.removeOfferDeploymentUpdateListeners;

export function isServiceAvailable() {
    return MainCGI.isServiceAvailable();
}

export async function initRdc(): Promise<boolean> {
    return send("initRdc", await MainCGI.getParams());
}

export async function disposeRdc(): Promise<void> {
    return send("disposeRdc");
}

export async function findDongle(): Promise<boolean> {
    return send("findDongle", await MainCGI.getParams());
}

export async function scanForDevices(): Promise<ScanResponse> {
    return send("scanDevices", await MainCGI.getParams());
}

export async function autoScanForDevices(): Promise<ScanResponse> {
    return send("autoScanDevices", await MainCGI.getParams());
}

export async function allDevices(): Promise<DeviceDbStatus[]> {
    return send("allDevices", await MainCGI.getParams());
}

export async function recoverDevice(MacAddressHex: string, DbDeviceId: string, IsLongRangeMode: boolean): Promise<boolean> {
    return send("recoverDevice", { MacAddressHex, DbDeviceId, IsLongRangeMode, EndpointArgs: await MainCGI.getParams() }); // TODO: Test this endpoint?
}

export async function downloadDeviceData(MacAddressHex: string, DbDeviceId: string, IsLongRangeMode: boolean, Rssi: number,
    IsContinuousTestMode: boolean): Promise<{
        result: boolean;
        readings: TakenReading[];
        connectionError: boolean;
        newFirmwareAvailable?: boolean;
    }> {
    return send("downloadDeviceData", { MacAddressHex, DbDeviceId, IsLongRangeMode, Rssi, IsContinuousTestMode, EndpointArgs: await MainCGI.getParams() });
}

export async function updateDeviceConfig(MacAddressHex: string, DbDeviceId: string, IsLongRangeMode: boolean, Rssi: number,
    IsContinuousTestMode: boolean): Promise<{
        result: boolean;
        readings: TakenReading[];
        connectionError: boolean;
        errorMessage?: string;
        newFirmwareAvailable?: boolean;
    }> {
    return send("updateDeviceConfig", { MacAddressHex, DbDeviceId, IsLongRangeMode, Rssi, IsContinuousTestMode, EndpointArgs: await MainCGI.getParams() });
}

export function updateDevice(reply: boolean): Promise<void> {
    return send("updateDevice", reply);
}

export async function firmwareUpdate(MacAddressHex: string, DbDeviceId: string, IsLongRangeMode: boolean, Rssi: number): Promise<{
    result: boolean;
    readings: TakenReading[];
    connectionError: boolean;
    errorMessage?: string;
}> {
    return send("firmwareUpdate", { MacAddressHex, DbDeviceId, IsLongRangeMode, Rssi, EndpointArgs: await MainCGI.getParams() });
}

export async function triggerAdhoc(MacAddressHex: string, DbDeviceId: string, IsLongRangeMode: boolean, Rssi: number): Promise<{
    result: boolean;
    readings: TakenReading[];
    connectionError: boolean;
    errorMessage?: string;
}> {
    return send("triggerAdhoc", { MacAddressHex, DbDeviceId, IsLongRangeMode, Rssi, EndpointArgs: await MainCGI.getParams() });
}

export async function subscribeUpdateOffers(callback: (arg: { currentVersion: string; newVersion: string; firmwareType: string }) => boolean) {
    return offerUpdate(callback);
}

export async function subscribeDeploymentUpdateOffers(callback: (arg: { currentDeployment: string; newDeployment: string; deploymentUpdateAvailable: boolean }) => boolean) {
    return offerDeploymentUpdate(callback);
}

export async function subscribeExtractionOffers(callback: (arg: {}) => boolean) {
    return offerExtraction(callback);
}

export async function unsubscribeUpdateOffers() {
    return removeOfferUpdateListeners();
}
