import * as React from "react";
import { Component } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Delete, Edit } from "@mui/icons-material";
import { Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Tooltip, Button } from "@mui/material";
import Services from "Services/Platform/Services";
import { Alert } from "@mui/lab";
import { Dcuser } from "@inductosense/typescript-fetch";
import DcUserFormModalPanel from "./DcUserFormModalPanel";
import NoDataSvg from "Graphics/Icons/NoDataSvg.svg";
import IconButton from "../Components/Buttons/IconButton";

interface DcUserListPanelState {
    isUserFormShown: boolean;
    editingUser?: Dcuser;
    isReadyToRender: boolean;
    userList: Dcuser[] | null;
    itemsChecked: boolean[];
    errorMessage?: string;
}

export default class DcUserListView extends Component<{}, DcUserListPanelState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            isUserFormShown: false,
            isReadyToRender: false,
            userList: null,
            itemsChecked: []
        };
    }

    async componentDidMount() {
        await this.fetchUsers();
    }

    async fetchUsers() {
        const userList = await Services.DcUsersService.getUsers();
        console.log("new userList", userList);
        this.setState({
            userList,
            itemsChecked: new Array(userList.length).fill(false),
            isReadyToRender: true
        });
    }

    dcUserRow(dcUser: Dcuser, index: number) {
        return <TableRow sx={{"&:hover":{background: "#f9ede7"}, background: (index !== undefined && index % 2 !== 0) ? "#fafafa" : "" }} key={dcUser.username+"-"+index}>
            <TableCell align="center">
                { dcUser.username }
            </TableCell>
            <TableCell align="center">
                <div style={{ display: "flex", justifyContent: "center" }} >
                    <Tooltip title="Edit" > 
                        <div>
                            <IconButton
                                icon={<Edit color="primary"/>}
                                onClick={() => this.onEditClick(index)}
                            />
                        </div>
                    </Tooltip>
                    <Tooltip title="Delete User" >
                        <div>
                            <IconButton
                                testId="device-test"
                                icon={<Delete color="primary" />}
                                onClick={() => this.onDeleteClick(index)}
                            />
                        </div>
                    </Tooltip>
                </div>
            </TableCell>   
        </TableRow>
    }

    render() {
        const { userList, isUserFormShown, isReadyToRender, errorMessage, editingUser } = this.state;
        if (!isReadyToRender) return null;

        return (
            <div>
                {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null}
                <Button 
                    variant="contained"
                    startIcon={<AddIcon />}
                    sx = {{
                            padding:"3px 20px",
                            "&:hover": {
                                background: "#a54d2d",
                                border: "unset"
                            },
                            border:"1px solid #ec6f41",
                            backgroundColor: "#ec6f41",
                            color: "#fff",
                            lineHeight:"23px",
                            fontSize: "14px",
                            textTransform: "none",
                            height:"38px",
                            marginBottom: "20px"
                        }}
                    onClick={this.onCreateClick.bind(this)}
                >
                    Add User
                </Button>
                <TableContainer>
                    <Table>
                    <TableHead sx={{"& .MuiTableCell-root":{fontWeight:"bold",fontSize:"14px",background: "#f9ede7"}, "& .MuiTableCell-head":{background: "#f9ede7"}}}>
                        <TableRow>
                            <TableCell align="center">
                                User Name
                            </TableCell>
                            <TableCell align="center">
                                Actions
                            </TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {(userList &&  userList.length > 0) ? userList
                                .map((user, index) => this.dcUserRow(user, index))
                            : 
                            <TableRow>
                                <TableCell align="center" colSpan={8}>
                                    <NoDataSvg />
                                    <div>No User</div>
                                </TableCell>
                            </TableRow> 
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <DcUserFormModalPanel
                    shouldBeShown={isUserFormShown}
                    initialUser={editingUser}
                    onClose={() => this.setState({ isUserFormShown: false })}
                    onUserUpdated={() => this.fetchUsers()}
                />
            </div>
        );
    }

    selectAll() {
        this.setState({
            itemsChecked: new Array(this.state.userList!.length).fill(true)
        });
    }

    onCreateClick() {
        this.setState({
            isUserFormShown: true,
            editingUser: undefined
        });
    }

    onEditClick(index: number) {
        this.setState({
            isUserFormShown: true,
            editingUser: this.state.userList![index]
        });
    }

    async onDeleteClick(index: number) {
        if (this.state.userList) {
            await Services.DcUsersService.deleteDcUsersId(this.state.userList[index].id);

            await this.fetchUsers();
        }
    }
}
