import * as React from "react";
import { Component } from "react";
import { FormField } from "Forms/FormField";
import FieldIsRequiredValidator from "Validation/Fields/FieldIsRequiredValidator";
import Form from "Forms/Form";
import TextField from "Components/Input/TextField";
import { FormSection, FormFieldChange } from "Types/Forms";
import Validator from "Validation/Validator";
import StringRepresentsAFourDigitPinOrNothingValidator from "Validation/Strings/StringRepresentsAFourDigitPinOrNothingValidator";
import { Alert } from "@mui/lab";
import { Dcuser } from "@inductosense/typescript-fetch";
import SaveAndCancelButtonsFormFooter from "../Forms/Shared/SaveAndCancelButtonsFormFooter";
import Services from "../Services/Platform/Services";
import StringRepresentsAValidRfidValidator from "../Validation/Strings/StringRepresentsAValidRfidValidator";
import ServiceGeneralError from "../Services/Platform/ServiceGeneralError";
import * as MainCGI from "Services/Electron/MainCGI";
import StringLengthMaximumValidator from "../Validation/Strings/StringLengthMaximumValidator";
import StringContainsOnlyPrintableASCIICharsValidator from "Validation/Strings/StringContainsOnlyPrintableASCIICharsValidator";

const footerStyle: React.CSSProperties = {
    marginTop: 16
}

const fieldWidth = 375;

interface DcUserFormProps {
    initialUser?: Dcuser;
    onSave(): void;
    onSaveComplete(): void;
    onSaveFailed(): void;
    onCancel(): void;
}

interface DcUserFormState {
    fields: Map<string, FormField>;
    userTriedToSave: boolean;
    isSaving: boolean;
    errorMessages: string[];
}

export default class DcUserForm extends Component<DcUserFormProps, DcUserFormState> {
    private fieldsRequiredValidator: Validator<string>;
    private pinValidator: Validator<string>;
    private rfidValidator: Validator<string>;
    private length16Validator: Validator<string>;
    private length32Validator: Validator<string>;
    private printableAsciiValidator: Validator<string>;

    constructor(props: DcUserFormProps) {
        super(props);

        const { initialUser } = this.props;

        this.fieldsRequiredValidator = new FieldIsRequiredValidator("This field is required");
        this.pinValidator = new StringRepresentsAFourDigitPinOrNothingValidator("Must be a four-digit numeric pin");
        this.rfidValidator = new StringRepresentsAValidRfidValidator("Must be a valid RFID beginning with EEE2", "EEE2");
        this.printableAsciiValidator = new StringContainsOnlyPrintableASCIICharsValidator();
        this.length16Validator = new StringLengthMaximumValidator(16);
        this.length32Validator = new StringLengthMaximumValidator(32);

        console.log("initialUser", initialUser);

        this.state = {
            fields: new Map([
                ["Username", new FormField(
                    initialUser?.username ?? null,
                    [this.fieldsRequiredValidator, this.printableAsciiValidator, this.length16Validator]
                )],
                ["Company", new FormField(
                    initialUser?.company ?? null,
                    [this.printableAsciiValidator, this.length32Validator]
                )],
                ["RFID", new FormField(
                    initialUser?.rfid ?? "EEE200000000000000000000",
                    [this.rfidValidator, this.fieldsRequiredValidator]
                )],
                ["Pin (4 digits)", new FormField(
                    initialUser?.pin ?? null,
                    [this.pinValidator, this.fieldsRequiredValidator]
                )]
            ]),
            userTriedToSave: false,
            isSaving: false,
            errorMessages: []
        };
    }

    render() {
        return (
            <>
                {this.state.errorMessages.map((error, index) =>
                    <Alert key={index} severity="error" style={{ marginBottom: 2 }}>{error}</Alert>
                )}
                <Form
                    sections={this.sections()}
                    footer={this.footer()}
                />
            </>
        );
    }

    private sections(): FormSection[] {
        return [
            this.usernameSection(),
            this.companySection(),
            this.rfidSection(),
            this.pinSection()
        ];
    }

    private footer() {
        return (
            <div style={footerStyle}>
                <SaveAndCancelButtonsFormFooter
                    isSaving={this.state.isSaving}
                    onSaveButtonClick={() => this.onSaveButtonClick()}
                    onCancelButtonClick={() => this.onCancelButtonClick()}
                />
            </div>
        );
    }

    private usernameSection(): FormSection {
        const fieldName = "Username";
        const field = this.state.fields.get(fieldName);
        return {
            marginTop: 6,
            label: { text: fieldName },
            control: {
                isEditable: true,
                node: <TextField
                    width={fieldWidth}
                    autoComplete="username"
                    initialValue={field?.value}
                    isDisabled={this.state.isSaving}
                    onValueChanged={change => this.onValueChanged(fieldName, change)}
                    validators={field?.validators} />
            }
        };
    }

    private rfidSection(): FormSection {
        const fieldName = "RFID";
        const field = this.state.fields.get(fieldName);
        return {
            marginTop: 6,
            label: { text: fieldName },
            control: {
                isEditable: true,
                node: <TextField
                    width={fieldWidth}
                    initialValue={field?.value}
                    isDisabled={this.state.isSaving}
                    validators={field?.validators}
                    onValueChanged={change => this.onValueChanged(fieldName, change)} />
            }
        }
    }

    private pinSection(): FormSection {
        const fieldName = "Pin (4 digits)";
        const field = this.state.fields.get(fieldName);
        return {
            marginTop: 6,
            label: { text: fieldName },
            control: {
                isEditable: true,
                node: <TextField
                    width={fieldWidth}
                    initialValue={field?.value}
                    isDisabled={this.state.isSaving}
                    validators={field?.validators}
                    onValueChanged={change => this.onValueChanged(fieldName, change)} />
            }
        }
    }

    private companySection(): FormSection {
        const fieldName = "Company";
        const field = this.state.fields.get(fieldName);
        return {
            marginTop: 6,
            label: { text: fieldName },
            control: {
                isEditable: true,
                node: <TextField
                    width={fieldWidth}
                    initialValue={field?.value}
                    isDisabled={this.state.isSaving}
                    validators={field?.validators}
                    onValueChanged={change => this.onValueChanged(fieldName, change)} />
            }
        }
    }

    private async onSaveButtonClick() {
        if (!this.state.userTriedToSave) this.setState({ userTriedToSave: true });
        if (this.formValuesAreValid()) {
            this.props.onSave();
            this.setState({ isSaving: true });
            await this.saveForm();
            this.setState({ isSaving: false });
        }
    }

    private onCancelButtonClick() {
        this.props.onCancel();
    }

    private onValueChanged(name: string, change: FormFieldChange) {
        const newFields = this.state.fields;
        const newField = newFields.get(name);
        newField!.value = change.newValue;
        newFields.set(name, newField!);
        this.setState({ fields: newFields });
    }

    private formValuesAreValid() {
        for (const f of this.state.fields.values()) {
            if (!f.isValid()) return false;
        }
        return true;
    }

    private async saveForm() {
        const { fields } = this.state;

        // If form is valid this field must exist
        const username = fields.get("Username")?.value!;

        const pin = fields.get("Pin (4 digits)")?.value || null;
        const rfid = fields.get("RFID")?.value || null;
        const company = fields.get("Company")?.value || null;

        try {
            if (this.props.initialUser !== undefined) {
                await Services.DcUsersService.putDcUsersId(this.props.initialUser.id, { username, pin, rfid, company });
            } else {
                await Services.DcUsersService.postDcUsers({ username, pin, rfid, company });
            }
            await MainCGI.uploadDbAuto()
            this.props.onSaveComplete();
        }
        catch (ex) {
            if (ex instanceof ServiceGeneralError) {
                this.setState({
                    errorMessages: ex.responseErrors?.map(error => error.message) || []
                });
                this.props.onSaveFailed();
            } else {
                console.log("not instance of");
            }
        }
    }
}
