import * as React from "react";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { CSSProperties, FunctionComponent } from "react";
import SensorSvg from "Graphics/Icons/SensorSvg.svg"

// Stylesheet to fix issue of sensor selection not being visible on touchscreen
import "./TouchFocusBackgroundFix.css";
//import makeStyles from "@mui/styles/makeStyles";

const sensorStyle: CSSProperties = {
    padding: "1px 11px 1px",
    display: "flex",
    alignItems: "center"
};

const sensorLabelStyle: CSSProperties = {
    paddingRight: 10,
    flexShrink: 0,
    fontSize: "11pt"
};

interface SensorProps {
    id: string;
    name: string;
    checked: boolean;
    onCheckedChanged(newState: boolean): void;
    showCheckbox: boolean;
}

const Sensor: FunctionComponent<SensorProps> = ({ id, name, checked, onCheckedChanged, showCheckbox }) => {
    const style = sensorLabelStyle;

    // TODO: Test this

    return (
        <TreeItem
            itemId={id}
            label={
                <div style={sensorStyle} onClick={() => onCheckedChanged(!checked)}>
                    <div style={{marginRight:"5px"}}><SensorSvg/></div>
                    <b style={style}>
                        {showCheckbox ? <input type="checkbox" checked={checked} onChange={ev => onCheckedChanged(ev.target.checked)} /> : null}
                        {name}
                    </b>
                </div>
            }
        />
    );
};

export default Sensor;
