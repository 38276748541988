
import Services from "../Platform/Services";

declare global {
    interface Window {
        mainCgiApi: mainCgiApiResponse
    }
}

export interface mainCgiApiResponse {
    receive: (callback: (arg: { status: string }) => void) => void
    removeReceiveListeners: () => void
    send: <T>(eventName: string, args?: {}) => Promise<T>
    offerUpdate: ((callback: (arg: { currentVersion: string; newVersion: string; firmwareType: string }) => void) => void)
    removeOfferUpdateListeners: () => void
    offerDeploymentUpdate: ((callback: (arg: { currentDeployment: string; newDeployment: string; deploymentUpdateAvailable: boolean }) => void) => void)
    offerExtraction: ((callback: (arg: {}) => void) => void)
    // removeOfferDeploymentUpdateListeners: () => void
}

const receive = window.mainCgiApi?.receive;
const removeReceiveListeners = window.mainCgiApi?.removeReceiveListeners;

export function isServiceAvailable() {
    return !!window.mainCgiApi;
}

export async function send<T>(eventName: string, args?: {}) {
    return window.mainCgiApi.send(eventName, args) as T;
}

export async function getParams() {
    return {
        "Tenant": Services.getTenant(),
        "CustomServerUrl": await Services.getUrlPrefix(),
        "LocalDbName": Services.LocalDbName
    };
}

export function uploadDb(AuthHeaderValue: string, UrlPrefix: string, Tenant: string, ForceReUpload: boolean): Promise<{ syncErrors: object[] }> {
    console.log("UrlPrefix", UrlPrefix);
    return send("uploadDb", { AuthHeaderValue, UrlPrefix, Tenant, ForceReUpload })
}

export async function uploadDbAuto(ForceReUpload = false): Promise<{ syncErrors: object[] } | undefined> {
    const tenant = Services.getTenant();

    if (isServiceAvailable() && tenant !== null) {
        const token = Services.getLatestToken();
        if (token === null) return undefined; // Skip if offline

        return uploadDb(token, await Services.getUrlPrefix(), tenant, ForceReUpload);
    } else {
        return undefined;
    }
}
export async function listSavedDatabases(): Promise<{ fileName: string, displayName: string }[]> {
    return send("listSavedDatabases", {})
}

export async function subscribeStatusUpdate(callback: (arg: { status: string }) => void) {
    return receive(callback);
}

export async function unsubscribeStatusUpdate() {
    return removeReceiveListeners();
}
